<template>
  <div>
    <div v-if="posExist" class="ticket-pos-body">
      <article-ticket :cash="cash" @value="handleSelect" />
      <resume-ticket
        :items="items"
        @delete-item="handleDeleteItem"
        @clean="handleCleanItems"
        :showDialog="visibleUpdate"
        @closeDialog="handeCloseDialog"
        :cash="cash"
      />
    </div>
    <div v-else class="ticket-pos-open">
      <h2 class="mb-2">Ouverture de la caisse</h2>
      <open-pos
        class="pos-open"
        :visible="visibleOpen"
        @success="handleOpenSuccess"
      />
    </div>
  </div>
</template>

<script>
import { getMyPOSOpen } from "@/api/sales2";
import ResumeTicket from "./components/resume-ticket.vue";
import ArticleTicket from "./components/article-ticket.vue";
import OpenPos from "./components/open-pos.vue";

export default {
  name: "TICKET-POS",
  components: {
    ResumeTicket,
    ArticleTicket,
    OpenPos,
  },
  data() {
    return {
      items: [],
      posExist: false,
      visibleOpen: false,
      visibleUpdate: false,
      cash: {
        amount_close: 0,
        amount_open: 0,
        date_close: null,
        date_open: "",
        note_close: null,
        note_open: "",
        open_number: "",
        openclose_id: 0,
        pos_name: "",
        pos_number: "",
        pos_status: "",
        status: "",
        user_close: null,
        user_open: "",
      },
      activeName: "SALE",
    };
  },
  mounted() {},
  created() {
    this.fetchFichePos();
  },
  methods: {
    handleDeleteItem(articleId) {
      const index = this.items.findIndex((el) => el.article_id === articleId);
      this.items.splice(index, 1);
    },

    handleSelect(value) {
      const item = this.items.find((el) => el.article_id === value.article_id);
      if (item !== undefined) {
        item.quantity += 1;
      } else {
        this.items.push(value);
      }
    },
    handeCloseDialog() {
      this.visibleUpdate = true;
    },
    async fetchFichePos() {
      this.loading = true;
      await getMyPOSOpen().then((res) => {
        if (res.data) {
          this.cash = Object.assign({}, res.data);
          this.posExist = true;
          this.loading = false;
        } else {
          this.posExist = false;
        }
      });
    },

    handleCleanItems(value) {
      if (value) {
        this.items = [];
      }
    },
    handleOpenSuccess() {
      this.fetchFichePos();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.ticket-pos-menu {
  display: flex;
  justify-content: end;
  .ticket-pos-menu-item {
    margin-right: 12px;
  }
}
.ticket-pos-open {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 115px);
  .pos-open {
    width: 30%;
    padding: 18px;
    background-color: white;
    border-radius: 6px;
  }
}
.ticket-pos-body {
  display: flex;
  flex-direction: row;
  overflow: hidden;

  .ticket-pos-article {
    width: 70%;
  }
  .ticket-pos-invoice {
    display: flex;
    flex-direction: column;

    background: white;
    width: 30%;
    overflow: hidden;
    height: calc(100vh - 50px);
    .ticket-nav {
      border-bottom: 1px solid #dcdfe6;
      padding: 0px 12px;
      background: white;
    }
    .ticket-pos-content {
      padding: 4px 12px;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: start;
      height: 100%;
      overflow: scroll;
      .ticket-item {
        display: flex;
        padding: 6px 0px;
        border-bottom: 1px dashed #dcdfe6;
        font-size: 14px;
        font-weight: 400;
        .ticket-item-title {
          flex: 1 1 auto;
          display: flex;

          flex-direction: column;
          padding-left: 7px;
          .total {
            padding-top: 5px;
            font-weight: bold;
            color: $secondary;
          }
        }
        .ticket-item-qte {
          display: flex;
          align-items: center;
          justify-content: end;
          width: 100px;
        }
        .ticket-item-qte > .add-moin,
        .add-plus {
          display: flex;
          width: 22px;
          height: 22px;
          border: 1px solid #dcdfe6;
          justify-content: center;
          align-items: center;
          background: #dcdfe6;
          font-weight: 600;
          border-radius: 30px;
        }
        .qte {
          font-size: 16px;
          flex: 1 1 auto;
          text-align: center;
        }
        .ticket-item-title,
        .ticket-item-total {
          font-weight: 500;
        }
      }
    }
    .ticket-pos-footer {
      display: flex;
      flex-direction: column;
      justify-content: start;
      height: 27%;
      .resume {
        height: 65%;
        padding: 4px 0px;
        border-top: 1px solid #dcdfe6;
        border-bottom: 1px solid #dcdfe6;
        .item-pos-footer-item {
          display: flex;
          font-size: 14px;
          justify-content: space-between;
          padding: 0px 12px;
          margin-bottom: 3px;

          .amount {
            font-weight: 600;
          }
          .title-total,
          .total-ttc {
            color: #67c23a;
            padding: 6px 0px;
            font-weight: bold;
            font-size: 20px;
          }
        }
      }

      .valid-button {
        padding: 4px 12px;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
