<template>
  <div class="el-empty">
    <div class="el-empty__image">
      <svg
        width="648"
        height="633"
        viewBox="0 0 648 633"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_701_263)">
          <path
            d="M411.146 142.174H236.636C232.659 142.179 228.847 143.761 226.035 146.573C223.223 149.385 221.641 153.197 221.636 157.174V545.024L219.636 545.634L176.826 558.744C174.797 559.362 172.606 559.151 170.733 558.155C168.86 557.16 167.459 555.462 166.836 553.434L39.4961 137.484C38.8764 135.455 39.0876 133.263 40.0833 131.39C41.0791 129.516 42.7778 128.115 44.8062 127.494L110.776 107.294L302.026 48.7538L367.996 28.5538C369 28.2448 370.055 28.1368 371.101 28.236C372.147 28.3353 373.162 28.6397 374.09 29.132C375.018 29.6243 375.84 30.2947 376.509 31.1049C377.177 31.9152 377.679 32.8493 377.986 33.8538L410.536 140.174L411.146 142.174Z"
            fill="#F2F2F2"
          />
          <path
            d="M449.226 140.174L409.996 12.0339C409.343 9.89882 408.276 7.9133 406.856 6.19077C405.436 4.46825 403.69 3.04249 401.718 1.99494C399.747 0.947401 397.588 0.298606 395.366 0.0856568C393.143 -0.127292 390.901 0.0997786 388.766 0.75387L296.016 29.1439L104.776 87.6939L12.0264 116.094C7.71774 117.417 4.11003 120.395 1.99517 124.376C-0.119693 128.356 -0.568919 133.013 0.746113 137.324L134.826 575.254C135.894 578.734 138.05 581.78 140.976 583.945C143.903 586.11 147.446 587.28 151.086 587.284C152.771 587.285 154.447 587.032 156.056 586.534L219.636 567.074L221.636 566.454V564.364L219.636 564.974L155.466 584.624C151.663 585.783 147.555 585.387 144.044 583.521C140.533 581.656 137.905 578.474 136.736 574.674L2.66652 136.734C2.08761 134.851 1.88585 132.872 2.07277 130.911C2.2597 128.95 2.83163 127.045 3.75585 125.305C4.68007 123.565 5.93842 122.025 7.45882 120.772C8.97922 119.519 10.7318 118.578 12.6162 118.004L105.366 89.6039L296.606 31.0639L389.356 2.66389C390.786 2.22769 392.272 2.00528 393.766 2.00388C396.973 2.01109 400.094 3.04504 402.671 4.95431C405.248 6.86358 407.146 9.54782 408.086 12.6139L447.136 140.174L447.756 142.174H449.836L449.226 140.174Z"
            fill="#3F3D56"
          />
          <path
            d="M122.681 127.821C120.754 127.819 118.877 127.2 117.328 126.055C115.778 124.909 114.636 123.296 114.07 121.454L101.189 79.3824C100.843 78.2523 100.723 77.065 100.836 75.8885C100.949 74.7119 101.292 73.5691 101.846 72.5254C102.401 71.4816 103.156 70.5572 104.067 69.8052C104.979 69.0531 106.03 68.488 107.16 68.1421L283.099 14.2784C285.382 13.5819 287.847 13.8194 289.955 14.9388C292.062 16.0582 293.639 17.968 294.34 20.2491L307.221 62.3213C307.917 64.6037 307.679 67.069 306.559 69.1763C305.44 71.2836 303.531 72.8609 301.25 73.5621L125.31 127.426C124.458 127.687 123.572 127.82 122.681 127.821V127.821Z"
            fill="#FF5C00"
          />
          <path
            d="M190.154 44.9546C201.199 44.9546 210.154 36.0003 210.154 24.9546C210.154 13.9089 201.199 4.95459 190.154 4.95459C179.108 4.95459 170.154 13.9089 170.154 24.9546C170.154 36.0003 179.108 44.9546 190.154 44.9546Z"
            fill="#FF5C00"
          />
          <path
            d="M190.154 37.6193C197.148 37.6193 202.818 31.9491 202.818 24.9547C202.818 17.9602 197.148 12.29 190.154 12.29C183.159 12.29 177.489 17.9602 177.489 24.9547C177.489 31.9491 183.159 37.6193 190.154 37.6193Z"
            fill="white"
          />
          <path
            d="M447.136 140.174H236.636C232.13 140.18 227.809 141.973 224.622 145.16C221.436 148.347 219.643 152.667 219.636 157.174V564.974L221.636 564.364V157.174C221.641 153.197 223.223 149.385 226.035 146.573C228.847 143.761 232.659 142.179 236.636 142.174H447.756L447.136 140.174ZM630.636 140.174H236.636C232.13 140.18 227.809 141.973 224.622 145.16C221.436 148.347 219.643 152.667 219.636 157.174V615.174C219.643 619.681 221.436 624.001 224.622 627.188C227.809 630.374 232.13 632.167 236.636 632.174H630.636C635.143 632.167 639.463 630.374 642.65 627.188C645.837 624.001 647.63 619.681 647.636 615.174V157.174C647.63 152.667 645.837 148.347 642.65 145.16C639.463 141.973 635.143 140.18 630.636 140.174V140.174ZM645.636 615.174C645.631 619.151 644.05 622.963 641.238 625.775C638.426 628.587 634.613 630.169 630.636 630.174H236.636C232.659 630.169 228.847 628.587 226.035 625.775C223.223 622.963 221.641 619.151 221.636 615.174V157.174C221.641 153.197 223.223 149.385 226.035 146.573C228.847 143.761 232.659 142.179 236.636 142.174H630.636C634.613 142.179 638.426 143.761 641.238 146.573C644.05 149.385 645.631 153.197 645.636 157.174V615.174Z"
            fill="#3F3D56"
          />
          <path
            d="M525.636 184.174H341.636C339.25 184.171 336.963 183.222 335.275 181.535C333.588 179.848 332.639 177.56 332.636 175.174V131.174C332.639 128.788 333.588 126.5 335.276 124.813C336.963 123.126 339.25 122.177 341.636 122.174H525.636C528.023 122.177 530.31 123.126 531.997 124.813C533.685 126.5 534.634 128.788 534.636 131.174V175.174C534.634 177.56 533.685 179.848 531.997 181.535C530.31 183.222 528.023 184.171 525.636 184.174V184.174Z"
            fill="#FF5C00"
          />
          <path
            d="M433.636 125.174C444.682 125.174 453.636 116.22 453.636 105.174C453.636 94.1281 444.682 85.1738 433.636 85.1738C422.591 85.1738 413.636 94.1281 413.636 105.174C413.636 116.22 422.591 125.174 433.636 125.174Z"
            fill="#FF5C00"
          />
          <path
            d="M433.636 117.356C440.364 117.356 445.818 111.902 445.818 105.174C445.818 98.446 440.364 92.9919 433.636 92.9919C426.908 92.9919 421.454 98.446 421.454 105.174C421.454 111.902 426.908 117.356 433.636 117.356Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_701_263">
            <rect width="647.636" height="632.174" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="el-empty__description">
      <p>{{ description }}</p>
    </div>
    <!---->
  </div>
</template>

<script>
export default {
  name: "Empty",
  props: {
    description: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped></style>
