import request from "@/utils/request";

export function createEmployee(data) {
  return request({
    url: "/employees",
    method: "post",
    data,
  });
}


export function updateEmployee(data,number) {
    return request({
      url: `/employees/${number}`,
      method: "put",
      data,
    });
  }

  export function getEmployees(query) {
    return request({
      url: `/employees`,
      method: "get",
      params : query
    });
  }

  export function getEmployeesList(type) {
    return request({
      url: `/employees/list/${type}`,
      method: "get",
    });
  }

  export function getEmployeeType() {
    return request({
      url: `/employees/types`,
      method: "get",
    });
  }