<template>
  <div>
    <el-dialog
      title="Clôturer de la caisse"
      width="35%"
      top="9vh"
      :close-on-click-modal="false"
      :show-close="false"
      :close-on-press-escape="false"
      :visible.sync="visible"
      @open="handleRapport"
    >
      <el-form
        :model="openPosForm"
        :rules="ruleOpenPos"
        ref="openPosRef"
        :label-position="labelPosition"
      >
        <div class="resume-dialog">
          <div class="item" style="font-weight: bold">
            <span> MONTANT À L'OUVERTURE :</span>
            <span>{{ cash.amount_open | moneyFilter }} {{user.currency_code}}</span>
          </div>
          <div class="item" style="font-weight: bold; color: #67c23a">
            <span> TOTAL DES VENTES :</span>
            <span>{{ rapport.total_sale | moneyFilter }} {{user.currency_code}}</span>
          </div>

          <div class="item" style="font-size: 13px">
            <span>TOTAL ANNULATION :</span>
            <span>{{ rapport.total_canceled | moneyFilter }} {{user.currency_code}}</span>
          </div>
          <div class="item" style="font-size: 13px">
            <span>TOTAL RETOURNÉ :</span>
            <span>{{ total_refund | moneyFilter }} {{user.currency_code}}</span>
          </div>

          <div class="item" style="font-weight: bold">
            <span>ECART DE SOLDE :</span>
            <span
              >{{
                (openPosForm.amount -
                  (cash.amount_open +
                    rapport.total_sale -
                    rapport.total_refund))
                  | moneyFilter
              }}
              {{user.currency_code}}</span
            >
          </div>
        </div>
        <el-form-item prop="amount" label="Entrer le montant à la clôture">
          <el-input-number
            :controls="false"
            style="width: 100%"
            v-model="openPosForm.amount"
          >
            <div>{{user.currency_code}}</div>
          </el-input-number>
        </el-form-item>

        <el-form-item prop="note" label="Note">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 2 }"
            v-model="openPosForm.note"
          />
        </el-form-item>
      </el-form>

      <div slot="footer" class="border-t pt-2 dialog-footer">
        <el-button
          type="infos"
          :loading="loadingButton"
          @click="handleDialogClose()"
          >Annuler</el-button
        >
        <el-button
          type="primary"
          :loading="loadingButton"
          @click="handleClosePOS('openPosRef')"
          >Enregistrer</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { closePos, fetchRapportFiche } from "@/api/sales2";
import { toThousandFilter, uppercaseFirst } from "@/Filters";
import {mapGetters} from "vuex";
export default {
  name: "CLOSE-POS",
  filters: {
    moneyFilter: toThousandFilter,
    uppercaseFirstFilter: uppercaseFirst,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    cash: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      posList: [],
      loadingButton: false,
      labelPosition: "top",
      openPosForm: {
        pos_number: "",
        amount: 0,
        note: "",
      },
      ruleOpenPos: {
        amount: [
          {
            required: true,
            message: "Le montant doit etre obligatoire",
            trigger: "blur",
          },
        ],
      },
      dialogVisible: this.visible,
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "createdAt",
        enbled: undefined,
        name: undefined,
      },
      rapport: {
        total_sale: 0,
        total_canceled: 0,
        total_refund: 0,
      },
    };
  },
  mounted() {},
  computed: {
      ...mapGetters(["user"]),
    diff() {
      return (
        this.openPosForm.amount -
        (this.rapport.total_sale -
          this.rapport.total_canceled -
          this.rapport.total_refund)
      );
    },
  },
  methods: {
    handleClosePOS(openPosRef) {
      this.$refs[openPosRef].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.openPosForm.pos_number = this.cash.pos_number;
          closePos(this.openPosForm)
            .then(() => {
              setTimeout(() => {
                this.$emit("success", this.openPosForm.pos_number);
                this.visible = false;
                this.loadingButton = false;
                this.$message({
                  title: "Success",
                  message: "Caisse fermée avec succès",
                  type: "success",
                  duration: 2000,
                });
                window.location.reload();
              }, 3000);
            })
            .catch((error) => {
              setTimeout(() => {
                if (error.status === 409) {
                  this.$message({
                    title: "Erreur",
                    message: error.data.message,
                    type: "error",
                    duration: 2000,
                  });
                }

                if (error.status === 400) {
                  this.$message({
                    title: "Erreur",
                    message: "Veuillez remplir les champs obligatoire",
                    type: "error",
                    duration: 2000,
                  });
                }
                this.loadingButton = false;
              }, 1.5 * 1000);
            });
        }
      });
    },
    handleDialogClose() {
      this.$emit("close-dialog", false);
    },
    async handleRapport() {
      await fetchRapportFiche(this.cash.open_number).then((res) => {
        this.rapport = Object.assign({}, res.data);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
