<template>
  <div>
    <el-form
      :model="openPosForm"
      :rules="ruleOpenPos"
      ref="openPosRef"
      :label-position="labelPosition"
    >
      <el-form-item
        prop="amount"
        label="Selectionner une caisse de vente à ouvrire"
      >
        <el-select
          style="width: 100%"
          v-model="openPosForm.pos_number"
          @change="handleSelect"
        >
          <el-option
            v-for="(item, index) in posList"
            :key="index"
            :value="item.number"
            :label="item.title"
          >
          </el-option>
        </el-select>
        <span v-if="posInProgress" class="text-second"
          >La caisse est en cours d'utilisation</span
        >
      </el-form-item>
      <el-form-item prop="amount" label="Entrer le montant à l'ouverture">
        <el-input-number
          :controls="false"
          style="width: 100%"
          v-model="openPosForm.amount"
        >
        </el-input-number>
      </el-form-item>

      <el-form-item prop="note" label="Note">
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 4 }"
          v-model="openPosForm.note"
        />
      </el-form-item>
    </el-form>

    <div slot="footer" class="border-t pt-2 dialog-footer">
      <el-button
        type="primary"
        style="width: 100%"
        :loading="loadingButton"
        @click="handleOpenPOS('openPosRef')"
        >Ouvrir</el-button
      >
    </div>
  </div>
</template>

<script>
import { openPos } from "@/api/sales2";
import { getAllPos } from "@/api/sales2";
export default {
  name: "OPEN-POS",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      posList: [],
      loadingButton: false,
      labelPosition: "top",
      openPosForm: {
        pos_number: "",
        amount: 0,
        note: "",
      },
      posInProgress: false,
      ruleOpenPos: {
        amount: [
          {
            required: true,
            message: "Le montant doit etre obligatoire",
            trigger: "blur",
          },
        ],
      },
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "createdAt",
        enbled: undefined,
        name: undefined,
      },
    };
  },
  mounted() {
    this.fetchPOS();
  },
  methods: {
    handleOpenPOS(openPosRef) {
      this.$refs[openPosRef].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          openPos(this.openPosForm)
            .then(() => {
              setTimeout(() => {
                this.$emit("success", this.openPosForm.pos_number);
                this.visible = false;
                this.loadingButton = false;
                this.$message({
                  title: "Success",
                  message: "Caisse ouverte avec succès",
                  type: "success",
                  duration: 2000,
                });
              }, 3000);
            })
            .catch((error) => {
              setTimeout(() => {
                if (error.status === 409) {
                  this.$message({
                    title: "Erreur",
                    message: error.data.message,
                    type: "error",
                    duration: 2000,
                  });
                }

                if (error.status === 400) {
                  this.$message({
                    title: "Erreur",
                    message: "Veuillez remplir les champs obligatoire",
                    type: "error",
                    duration: 2000,
                  });
                }
                this.loadingButton = false;
              }, 1.5 * 1000);
            });
        }
      });
    },
    async fetchPOS() {
      await getAllPos(this.listQuery).then((res) => {
        setTimeout(() => {
          this.posList = res.data.items;
          this.listQuery.total = parseInt(res.data.total_items);
          this.listQuery.page = parseInt(res.data.current_page) + 1;
          this.listLoading = false;
        }, 1000);
      });
    },
    handleSelect(e) {
      const item = this.posList.find((el) => el.number === e);
      if (item.status === "OPEN") {
        this.posInProgress = true;
      } else {
        this.posInProgress = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
