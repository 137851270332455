<template>
  <div class="ticket-pos-invoice">
    <div class="ticket-nav">
      <i class="el-icon-receiving mr-2"></i>{{ cash.pos_name }}
    </div>
    <div
      class="ticket-pos-content"
      :class="{ justifyCenter: items.length === 0 }"
    >
      <div v-if="items.length > 0">
        <div v-for="(item, index) in items" :key="index">
          <div @click="handleUpdateDialog(item)" class="ticket-item">
            <div class="qty">{{ item.quantity }}</div>
            <div class="title">
              <span> {{ item.article_name }}</span>
            </div>
            <div class="total">
              {{ (item.price * item.quantity) | moneyFilter }}
            </div>
          </div>
        </div>
      </div>
      <empty v-else description="Le panier est vide"> </empty>
    </div>

    <div class="ticket-pos-footer">
      <div class="resume">
        <div class="item-pos-footer-item">
          <span class="text-gray-500">Total HT :</span>
          <span class="amount">{{ totalHT | moneyFilter }}</span>
        </div>
        <div class="item-pos-footer-item">
          <span class="text-gray-500">Total Qté :</span>
          <span class="amount">{{ totalQuanty }}</span>
        </div>
        <!-- <div class="item-pos-footer-item">
          <span class="title-total">Total à payer:</span>
          <span class="total-ttc">{{ totalTTC | moneyFilter }} {{user.currency_code}}</span>
        </div> -->
      </div>
      <div class="valid-button">
        <!--     <el-button
          type="infos"
          style="width: 15%; height: 60px; font-size: 16px"
          icon="el-icon-printer"
        >
        </el-button>
        <el-button
          type="infos"
          style="width: 15%; height: 60px; font-size: 16px"
          icon="el-icon-shopping-bag-1"
        >
        </el-button> -->
        <el-button
          @click="handleConfirmerPayment"
          type="primary"
          style="width: 100%; height: 60px; font-size: 16px; font-weight: bold"
          :disabled="items.length === 0"
          >Payer ({{ totalTTC | moneyFilter }} {{user.currency_code}})
        </el-button>
      </div>
    </div>

    <div class="confirm">
      <el-dialog
        :visible.sync="dialogTicketPaymentVisible"
        width="30%"
        :close-on-click-modal="false"
      >
        <div v-if="!ticketSuccess">
          <div>
            <div class="confirme-amount">
              <div
                class="item-pos-footer-item input-pos-amountreceved"
                style="margin-bottom: 3px"
              >
                <!--    <el-input-number
                  label="Montant reçu"
                  v-model="ticket.amount_received"
                  ref="enterAmount"
                  :min="0"
                  :controls="false"
                  :autofocus="true"
                ></el-input-number> -->
                <span style="font-size: 3rem; font-weight: bold">{{
                  totalTTC | moneyFilter
                }}</span>
              </div>
              <span v-if="ticket.amount_received <= totalTTC" class="primary"
                >MONTANT PERÇU EN {{user.currency_code}}</span
              >
              <span v-else style="color: #ff5c00"
                >Monnaie :
                {{ (ticket.amount_received - totalTTC) | moneyFilter }} EN
                {{user.currency_code}}</span
              >
              <div class="mt-8" style="width: 70%">
                <span for="">Montant reçu</span>
                <el-input-number
                  :controls="false"
                  label="Montant reçu"
                  v-model="ticket.amount_received"
                ></el-input-number>

                <el-select
                  v-model="ticket.sale_agent_number"
                  filterable
                  placeholder="Agent commercial"
                  clearable
                  class="mt-2"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in employees"
                    :key="item.number"
                    :label="item.full_name"
                    :value="item.number"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div>
            <!--  <num-pad @value="pickValue" @backspace="handleBackspace" /> -->
          </div>
        </div>
        <div class="success-component" v-if="ticketSuccess">
          <success-icon />
          <p class="mt-3" style="color: #4bb71b">
            La vente a été effectuer avec succès
          </p>
          <div class="mt-2">
            Montant Total :
            <span style="font-weight: bold"
              >{{ totalTTC | moneyFilter }} {{user.currency_code}}</span
            >
          </div>

          <el-button
            @click="exportTicketPDF"
            class="mt-4"
            type="info"
            plain
            icon="el-icon-printer"
            >Imprimer le reçu</el-button
          >
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button
            v-if="!ticketSuccess"
            @click="dialogTicketPaymentVisible = false"
            style="width: 50%; height: 50px; font-size: 18px"
            >Annuler</el-button
          >
          <el-button
            v-if="!ticketSuccess"
            :disabled="ticket.amount_received < totalTTC"
            @click="createTicketPOS"
            type="primary"
            style="width: 50%; height: 50px; font-size: 18px"
            :loading="loadingButton"
            >Confirmer</el-button
          >
          <el-button
            v-if="ticketSuccess"
            @click="handleTerminal"
            type="primary"
            style="width: 100%; height: 50px; font-size: 18px"
            >Terminer</el-button
          >
        </div>
      </el-dialog>
    </div>

    <div class="confirm">
      <el-dialog
        :visible.sync="dialogUpdateVisible"
        :before-close="handleCloseDialogUpdate"
        width="30%"
      >
        <el-form :model="articleLineUpdate" :label-position="labelPosition">
          <el-form-item prop="date" label="Quantité">
            <div class="number-center-text">
              <el-input-number
                v-model="articleLineUpdate.quantity"
                :min="1"
              ></el-input-number>
            </div>
          </el-form-item>
          <el-form-item prop="date" label="Prix">
            <div class="number-center-text">
              <el-input-number
                v-model="articleLineUpdate.price"
                class="number-center-text"
                :controls="false"
                :min="articleLineUpdate.price_min"
              ></el-input-number>
            </div>
          </el-form-item>

          <div class="mt-5">
            Montant Total :
            <span style="font-weight: bold"
              >{{
                (articleLineUpdate.quantity * articleLineUpdate.price)
                  | moneyFilter
              }}
              {{user.currency_code}}</span
            >
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            type="danger"
            @click="handleDeleteItem"
            style="width: 50%; height: 50px; font-size: 18px"
            >Supprimer</el-button
          >
          <el-button
            type="primary"
            style="width: 50%; height: 50px; font-size: 18px"
            @click="handleUpdateItem"
            :loading="loadingButton"
            >Confirmer</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { exportTicketPDF } from "@/api/export";
import { toThousandFilter } from "@/Filters";
import { getEmployeesList } from "@/api/employee";
//import NumPad from "@/components/keyboard";
import Empty from "@/components/Empty";
import { createTicket } from "@/api/sales2";
import SuccessIcon from "@/components/Success";
import {mapGetters} from "vuex";
export default {
  name: "RESUME-TICKET",
  filters: {
    moneyFilter: toThousandFilter,
  },
  components: { Empty, /*NumPad,*/ SuccessIcon },
  props: {
    items: {
      type: Array,
      required: true,
    },
    cash: {
      type: Object,
      required: true,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dowloandLoading: false,
      dialogTicketPaymentVisible: false,
      dialogUpdateVisible: false,
      ticketSuccess: false,
      loadingButton: false,
      labelPosition: "top",
      employees: [],
      articleLineUpdate: {
        article_id: "",
        quantity: 0,
        price: 0,
        price_min: 0,
      },
      ticket: {
        ticket_no: undefined,
        contact_id: "",
        terms: "",
        comment: "",
        percent_discount: 0,
        percent_tax: 0,
        payment_method: "Espèce",
        card_bank: "",
        card_number: "",
        currency: "",
        pos_number: this.cash.pos_number,
        sale_agent_number: "",
        amount_received: 0,
        items: [],
      },
    };
  },
  computed: {
      ...mapGetters(["user"]),
    totalHT: function () {
      return this.items.reduce(
        (total, item) => total + item.quantity * item.price,
        0
      );
    },
    totalTTC: function () {
      return (
        this.totalHT *
        (1 + (this.ticket.percent_tax - this.ticket.percent_discount) / 100)
      );
    },
    totalQuanty: function () {
      return this.items.reduce((total, item) => total + item.quantity, 0);
    },
    itemsNoEmpty: function () {
      return this.ticket.items.some((item) => item.article_id !== "");
    },
  },
  mounted() {
    this.fetchEmployee();
  },
  methods: {
    async fetchEmployee() {
      await getEmployeesList("SALES_AGENT")
        .then((res) => {
          this.employees = res.data;
        })
        .catch((err) => {
          this.$message({
            message: err.data.message,
            type: "danger",
          });
        });
    },
    handlePlusArticle(id) {
      const item = this.items.find((el) => el.article_id === id);
      item.quantity++;
    },
    handleMinusArticle(id) {
      const item = this.items.find((el) => el.article_id === id);
      if (item.quantity > 1) {
        item.quantity--;
      }
    },
    handleDelete(id) {
      this.$emit("delete-item", id);
    },
    handleConfirmerPayment() {
      this.$nextTick(() => {
        this.dialogTicketPaymentVisible = true;
        this.ticket.amount_received = this.totalTTC;
      });
    },
    handleCancel() {
      this.dialogTicketPaymentVisible = false;
    },
    pickValue(value) {
      this.ticket.amount_received = this.ticket.amount_received + "" + value;
    },
    handleBackspace() {
      let amountReceived = this.ticket.amount_received.toString();

      if (amountReceived.length > 0) {
        this.ticket.amount_received = Number(
          amountReceived.substring(0, amountReceived.length - 1)
        );
      }
    },
    handlCancelTicket() {
      this.dialogTicketPaymentVisible = false;
    },
    rejetTicket() {
      this.ticket = {
        ticket_no: undefined,
        contact_id: "",
        terms: "",
        comment: "",
        percent_discount: 0,
        percent_tax: 0,
        payment_method: "Espèce",
        card_bank: "",
        card_number: "",
        currency: "",
        pos_number: this.cash.pos_number,
        amount_received: 0,
        sale_agent_number: "",
        items: [],
      };
    },
    createTicketPOS() {
      if (this.ticket.amount_received >= this.totalTTC) {
        this.loadingButton = true;
        this.ticket.pos_number = this.cash.pos_number;
        this.ticket.items = this.items;
        this.ticket.currency = this.user.currency_code;
        createTicket(this.ticket)
          .then((res) => {
            setTimeout(() => {
              this.ticket.ticket_no = res.data.ticket_no;
              this.loadingButton = true;
              this.ticketSuccess = true;
            }, 1.5 * 1000);
          })
          .catch((error) => {
            if (error.status === 409 || error.status === 404) {
              this.$message({
                title: "Erreur",
                message: error.data.message,
                type: "error",
                duration: 2000,
              });
            }

            if (error.status === 400) {
              this.$message({
                title: "Erreur",
                message: "Veuillez remplir les champs obligatoire",
                type: "error",
                duration: 2000,
              });
            }
            if (error.status === 500) {
              this.$message({
                title: "Erreur",
                message: "Erreur : Veuillez contacter votre administrateur",
                type: "error",
                duration: 2000,
              });
            }
            this.loadingButton = false;
          });
      }
    },
    handleTerminal() {
      this.rejetTicket();
      this.$emit("clean", true);
      this.dialogTicketPaymentVisible = false;
      this.ticketSuccess = false;
      this.loadingButton = false;
    },

    exportTicketPDF() {
      exportTicketPDF(this.ticket.ticket_no)
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/pdf",
            })
          );
          let iframe = document.createElement("iframe"); //load content in an iframe to print later
          document.body.appendChild(iframe);

          iframe.style.display = "none";
          iframe.src = url;
          iframe.onload = function () {
            setTimeout(function () {
              iframe.focus();
              iframe.contentWindow.print();
            }, 1);
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetArticle() {
      this.articleLineUpdate = {
        article_id: "",
        quantity: 0,
        price: 0,
        price_min: 0,
      };
    },
    handleUpdateDialog(item) {
      console.log(item);
      // this.dialogUpdateVisible = true;
      this.articleLineUpdate.article_id = item.article_id;
      this.articleLineUpdate.price = item.price;
      this.articleLineUpdate.price_min = item.price;
      this.articleLineUpdate.quantity = item.quantity;
      this.dialogUpdateVisible = true;
    },
    handleUpdateItem() {
      const item = this.items.find(
        (el) => el.article_id === this.articleLineUpdate.article_id
      );
      item.price = this.articleLineUpdate.price;
      item.quantity = this.articleLineUpdate.quantity;
      this.dialogUpdateVisible = false;
      this.resetArticle();
    },
    handleCloseDialogUpdate() {
      this.dialogUpdateVisible = false;
      this.resetArticle();
    },

    handeCloseDialog() {
      this.$emit("closeDialog", false);
    },
    handleDeleteItem() {
      const index = this.items.findIndex(
        (el) => el.article_id === this.articleLineUpdate.article_id
      );
      this.items.splice(index, 1);
      this.dialogUpdateVisible = false;
      this.resetArticle();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.justifyCenter {
  justify-content: center;
}
.ticket-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #dcdfe6;
  border-left: 1px solid #dcdfe6;
  padding: 12px 6px;
  height: 65px;
  background: white;
}

.ticket-pos-content {
  padding: 4px 12px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  .ticket-item {
    display: flex;
    padding: 12px 0px;
    border-bottom: 1px dashed #dcdfe6;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    align-items: center;
    .title {
      flex: 1 1 auto;
      width: 100px;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .total {
      font-weight: bold;
      margin-left: 20px;
    }
    .qty {
      margin-right: 20px;
    }
    .total,
    .qty {
      padding: 0px 5px;
    }
  }
  .ticket-item:hover {
    background-color: rgba($color: #dcdfe6, $alpha: 0.2);
  }
}
.ticket-pos-footer {
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 30%;

  .valid-button {
    padding: 4px 2px;
    display: flex;
    align-items: center;
  }
}

.resume {
  height: 65%;
  padding: 4px 0px;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
}
.confirme-amount {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-bottom: 12px;
}
.item-pos-footer-item {
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
  margin-bottom: 3px;

  .amount {
    font-weight: 600;
  }
  .title-total,
  .total-ttc {
    font-weight: bold;
    font-size: 20px;
  }
  .rendu {
    padding-top: 16px;
  }
}
.success-component {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
