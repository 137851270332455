import request from "@/utils/request";

// gestion des articles
export function getArticles(query) {
  return request({
    url: "/articles/fetch",
    method: "get",
    params: query,
  });
}

export function createArticle(data) {
  return request({
    url: "/articles",
    method: "post",
    data,
  });
}

export function updateArticle(data, articleId) {
  return request({
    url: `/articles/${articleId}`,
    method: "put",
    data,
  });
}

export function updatePriceArticle(data) {
  return request({
    url: `/articles/prices`,
    method: "put",
    data,
  });
}

export function getArticle(articleId) {
  return request({
    url: `/articles/${articleId}`,
    method: "get",
  });
}

export function activeArticle(articleId) {
  return request({
    url: `/articles/active/${articleId}`,
    method: "get",
  });
}

export function searchArticle(name) {
  return request({
    url: "/stock/search",
    method: "get",
    params: name,
  });
}

export function searchTransferStockArticle(query) {
  return request({
    url: "/stock/transfers/search",
    method: "get",
    params: query,
  });
}

export function nextArticleNumero() {
  return request({
    url: `/articles/next-numero`,
    method: "get",
  });
}

export function getStatistique() {
  return request({
    url: `/stock/fetch/stats`,
    method: "get",
  });
}

// gestion des categories
export function getCategories() {
  return request({
    url: "/categories",
    method: "get",
  });
}

export function createCategorie(data) {
  return request({
    url: "/categories",
    method: "post",
    data,
  });
}

export function updateCategorie(data, categoryId) {
  return request({
    url: `/categories/${categoryId}`,
    method: "put",
    data,
  });
}

// gestion des unites
export function getUoms() {
  return request({
    url: "/uoms",
    method: "get",
  });
}

export function createUom(data) {
  return request({
    url: "/uoms",
    method: "post",
    data,
  });
}

export function updateUom(data, categoryId) {
  return request({
    url: `/uoms/${categoryId}`,
    method: "put",
    data,
  });
}

// mouvement de stock
export function fetchMovementStock(query) {
  return request({
    url: "/movement-stock/fetch",
    method: "GET",
    params: query,
  });
}

export function fetchMovementStockForArticle(query, articleId) {
  return request({
    url: `/movement-stock/article/${articleId}`,
    method: "GET",
    params: query,
  });
}

export function fetchTypesMovementStock() {
  return request({
    url: "/movement-stock/types",
    method: "GET",
  });
}

export function fetchMovementStatistique() {
  return request({
    url: "/movement-stock/statistique",
    method: "GET",
  });
}

// gestion des bon d'entree

export function fetchTypesBon() {
  return request({
    url: "/stock/voucher/type",
    method: "GET",
  });
}

export function createBonSortie(data) {
  return request({
    url: "/stock/exit-voucher",
    method: "post",
    data,
  });
}

export function createBonEntree(data) {
  return request({
    url: "/stock/entry-voucher",
    method: "post",
    data,
  });
}

export function fetchBonEntree(numero) {
  return request({
    url: `/stock/entry-voucher/${numero}`,
    method: "GET",
  });
}

export function getListBonEntree(query) {
  return request({
    url: `/stock/entry-voucher/fetch`,
    method: "GET",
    params: query,
  });
}

export function getListBonSortie(query) {
  return request({
    url: `/stock/exit-voucher/fetch`,
    method: "GET",
    params: query,
  });
}

export function fetchBonSortie(numero) {
  return request({
    url: `/stock/exit-voucher/${numero}`,
    method: "GET",
  });
}

export function cancelBonEntree(numero) {
  return request({
    url: `/stock/entry-voucher/cancel/${numero}`,
    method: "DELETE",
  });
}

export function cancelBonSortie(numero) {
  return request({
    url: `/stock/exit-voucher/cancel/${numero}`,
    method: "DELETE",
  });
}
