<template>
  <div class="ticket-pos-article">
    <div class="header shadow-sm">
      <el-select
        style="margin-right: 4px"
        placeholder="Categorie"
        v-model="listQuery.category"
        @change="fetchArticles()"
        clearable
      >
        <el-option
          v-for="(item, index) in categories"
          :key="index"
          :value="item.id"
          :label="item.name"
        >
        </el-option>
      </el-select>
      <div class="search">
        <el-input
          v-model="listQuery.name"
          @change="fetchArticles()"
          @keydown.native.enter="fetchArticles()"
          placeholder="Rechercher un article"
          clearable
        >
        </el-input>
      </div>
      <el-button
        type="primary"
        class="mr-2"
        icon="el-icon-receiving"
        @click="handleClose()"
        >Clôturer la caisse</el-button
      >
      <el-button
        icon=" el-icon-document"
        style="font-weight: bold"
        @click="handleListeDialog()"
        class="mr-2"
      >
      </el-button>
      <el-button-group>
        <el-button icon="el-icon-arrow-left" @click="handleBack()"></el-button>
        <el-button
          icon=" el-icon-arrow-right"
          style="font-weight: bold"
          @click="handleNext()"
        >
        </el-button>
      </el-button-group>
    </div>
    <div class="container" v-loading="listLoading">
      <div class="article">
        <div
          class="artilce-item shadow-md"
          :style="{
            backgroundColor: colors[Math.floor(Math.random() * colors.length)],
          }"
          v-for="(item, index) in items"
          :key="index"
          @click="handleSelectArticle(item)"
        >
          <div class="name">{{ item.article_name }}</div>
          <div class="price">{{ item.selling_price | moneyFilter }} {{user.currency_code}}</div>
        </div>
      </div>
    </div>
    <close-open
      :visible="dialogVisible"
      :cash="cash"
      @close-dialog="handleClose"
    />
    <list-ticket
      :visible="dialogListVisible"
      @close-dialog="handleListeDialog"
      :ficheNumber="cash.open_number"
    />
  </div>
</template>

<script>
import { toThousandFilter, uppercaseFirst } from "@/Filters";
import { getCategories, getArticles } from "@/api/article";
import CloseOpen from "./close-pos.vue";
import ListTicket from "./ticket-list.vue";
import {mapGetters} from "vuex";
//import Pagination from "@/components/Pagination";
export default {
  name: "ARTICLE-TICKET",
  components: { CloseOpen, ListTicket },
  props: {
    cash: {
      type: Object,
      required: true,
    },
  },
  filters: {
    moneyFilter: toThousandFilter,
    uppercaseFirstFilter: uppercaseFirst,
  },
  data() {
    return {
      article: {
        article_name: "",
        price: 0,
        quantity: 1,
        article_id: "",
      },
      items: [],
      dialogVisible: false,
      dialogListVisible: false,
      categories: [],
      colors: [
        "#faf6ee",
        "#faf9e3",
        "#e2f3e2",
        "#eff6fb",
        "#f7efec",
        "#f5f1fc",
        "#fbeaf2",
        "#faebe1",
        "#ece8cc",
        "#edf1da",
        "#e1dede",
      ],
      listLoading: false,
      totalPage: 0,
      listQuery: {
        total: 0,
        page: 1,
        size: 50,
        sort: "desc",
        sortDir: "createdAt",
        name: undefined,
        category: undefined,
        warehouseId: undefined,
      },
    };
  },
  mounted() {
    this.fetchArticles();
    this.fetchCategories();
  },
    computed : {
        ...mapGetters(["user"]),
    },
  methods: {
    async fetchCategories() {
      await getCategories()
        .then((res) => {
          this.categories = res.data;
          // console.log(this.categories);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchArticles() {
      this.listLoading = true;
      this.listQuery.warehouseId = this.cash.warehouse_id;
      console.log(this.cash);
      await getArticles(this.listQuery)
        .then((res) => {
          setTimeout(() => {
            this.items = res.data.items;
            this.totalPage = parseInt(res.data.total_page);
            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchDataScrol() {
      this.listQuery.page++;
      this.fetchArticles();
    },
    handleSelectArticle(row) {
      this.$emit("value", {
        article_name: row.article_name,
        price: row.selling_price,
        quantity: 1,
        sku: row.sku,
        article_id: row.article_id,
        warehouse_code: this.cash.warehouse_code,
      });
    },
    handleBack() {
      if (this.listQuery.page > 1) {
        this.listQuery.page -= 1;
        this.fetchArticles();
      }
    },
    handleNext() {
      if (this.listQuery.page < this.totalPage) {
        this.listQuery.page += 1;
        this.fetchArticles();
      }
    },
    handleClose() {
      this.dialogVisible = !this.dialogVisible;
    },
    handleListeDialog() {
      this.dialogListVisible = !this.dialogListVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-pos-article {
  display: flex;
  flex-direction: column;

  .container {
    overflow-y: auto;
    height: calc(100vh - 106px);
  }
  .header {
    display: flex;
    height: 65px;
    background: white;
    padding: 6px;
    align-items: center;
    .search {
      flex: 1 1 auto;
      margin-right: 4px;
    }
  }
  .article {
    padding: 12px;
    padding-top: 12px;
    flex: 1 1 auto;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(7, 1fr);
    align-items: start;
    padding: 12px;
    .artilce-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 13px;
      flex: 1;
      width: 150px;
      height: 120px;
      padding: 8px;
      border-radius: 6px;
      cursor: pointer;
      .name {
        flex: 1 1 auto;
        text-align: center;
      }
      .price {
        font-weight: 600;
      }
    }
  }
}
</style>
